<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getWmsDataPage"
      :deleteFun="deleteWmsDataInfo"
      :createFun="createWmsDataInfo"
      :updateFun="updateWmsDataInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getWmsDataPage, getWmsDataOption, createWmsDataInfo, updateWmsDataInfo, deleteWmsDataInfo } from '@/api/wms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '货名', name: 'name', fieldType: '', queryType: '%'
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '货名',
          dataIndex: 'name'
        },
        {
          title: '编号',
          dataIndex: 'number'
        },
        {
          title: '包装形式',
          dataIndex: 'wrapper_name'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入货名', trigger: 'blur' }
        ]
      },
      modelTitle: '仓库货名',
      formFields: [
        {
          label: '货名', name: 'name', type: 'default'
        },
        {
          label: '编号', name: 'number', type: 'default'
        },
        {
          label: '包装形式',
          name: 'wrapper_id',
          type: 'select',
          func: getWmsDataOption,
          params: 'wrapper',
          options: []
        },
        {
          label: '', name: 'wrapper_name', type: 'select_name', ref: 'wrapper_id'
        }
      ],
      moduleName: 'goods_name'
    }
  },
  methods: {
    getWmsDataPage,
    getWmsDataOption,
    createWmsDataInfo,
    updateWmsDataInfo,
    deleteWmsDataInfo
  }
}
</script>
